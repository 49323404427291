import { FC } from 'react';

import { IconProps } from './types';

export const CargoIcon: FC<IconProps> = ({ size, width = 25, height = 23 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 25 23"
		width={size ?? width}
		height={size ?? height}
	>
		<path
			fill="#06519E"
			d="M12.2579 11.5704c.75.2083 1.4457.5583 2.0624 1.0291l6.7289-2.2416c.35-.1166.5375-.4958.4208-.8415l-2.5166-7.5498c-.1167-.35-.4957-.5375-.8416-.4208l-2.5458.85 1.3792 4.1414-2.5291.8375-1.379-4.1415-2.5666.8541c-.35.1167-.5375.4959-.4209.8417l2.2083 6.6414Zm11.7079 2.029-.425-1.2665c-.1167-.35-.4959-.5375-.8416-.4209l-8.8956 2.9665c-.7167-.9165-1.8166-1.5165-3.0623-1.5415L6.5997.9125A1.3351 1.3351 0 0 0 5.3331 0H.6666C.3 0 0 .3 0 .6666V2c0 .3667.3.6667.6666.6667h3.704l3.8416 11.5287c-1.0875.85-1.7375 2.2333-1.5 3.7707.2542 1.6416 1.5791 3.0124 3.2208 3.2999 2.5082.4458 4.679-1.45 4.7247-3.8582l8.8872-2.9666c.3459-.1166.5375-.4916.4209-.8416Zm-13.2995 5.7332c-1.1042 0-2-.8958-2-2 0-1.104.8958-1.9999 2-1.9999 1.1041 0 2 .8958 2 2 0 1.104-.8959 1.9999-2 1.9999Z"
			fillRule="evenodd"
		/>
	</svg>
);
