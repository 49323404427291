import { SVGAttributes } from 'react';

export const QuestionIcon = (props: SVGAttributes<SVGElement>) => {
	return (
		<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h24v24H0z" />
				<path
					d="M13.82 13.114v.8c0 1.105-.715 2-1.82 2s-1.795-.895-1.795-2V12.5m0 0c0-1.367.258-1.893 1.197-2.218 1-.36 2.4-1.262 2.4-1.91 0-.325-.092-.668-.54-1.263-.51-.54-1.542-.703-2.343-.234-.36.18-.667.559-.912 1.045a1.801 1.801 0 0 1-2.42.794c-.89-.433-1.25-1.515-.804-2.416.424-.848 1.19-1.984 2.427-2.597 2.156-1.262 5.066-.81 6.824 1.118.655.848 1.374 1.984 1.374 3.552 0 3.516-3.114 4.581-3.606 4.743m-3.597 6.075c0 .991.817 1.803 1.812 1.803.996 0 1.803-.812 1.803-1.803 0-.992-.807-1.803-1.803-1.803H12c-.995 0-1.794.811-1.794 1.803Z"
					fill="#FFF"
				/>
			</g>
		</svg>
	);
};
