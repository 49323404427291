import { FC } from 'react';

import { IconProps } from './types';

export const PhoneIcon: FC<IconProps> = ({ size, height = 22, width = 23 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 23 22"
		width={size ?? width}
		height={size ?? height}
	>
		<path
			fill="#06519E"
			d="M18.927 21.93c-.078 0-.165-.01-.252-.01-3.23-.35-6.366-1.47-9.081-3.23-2.517-1.6-4.698-3.78-6.3-6.3C1.532 9.67.417 6.52.074 3.28 0 2.47.243 1.69.756 1.08 1.27.46 1.992.08 2.79.01 2.88 0 2.969 0 3.058 0h3.029C7.566 0 8.84 1.1 9.048 2.58c.118.89.335 1.77.647 2.6.41 1.09.145 2.33-.676 3.16l-.721.72c1.176 1.85 2.742 3.41 4.585 4.59l.718-.72c.833-.82 2.075-1.09 3.169-.68.835.31 1.707.53 2.588.65 1.515.21 2.621 1.51 2.59 3.03v2.99c.004.8-.306 1.55-.87 2.12-.564.57-1.317.88-2.118.89h-.033ZM6.077 2H3.058c-.354.03-.595.15-.766.36-.171.2-.252.46-.228.73.308 2.9 1.316 5.75 2.913 8.22 1.451 2.28 3.419 4.25 5.697 5.7 2.461 1.59 5.295 2.6 8.202 2.92.353.03.594-.11.782-.3.188-.19.291-.44.29-.71V15.9c.013-.51-.356-.95-.86-1.02-1.021-.13-2.039-.39-3.019-.75-.369-.14-.782-.05-1.058.22l-1.267 1.27c-.319.32-.812.38-1.201.16-2.659-1.51-4.863-3.72-6.375-6.38-.223-.39-.156-.88.162-1.2L7.6 6.93c.27-.27.358-.68.221-1.05-.365-.98-.619-2-.755-3.03-.068-.48-.493-.85-.989-.85Z"
			fillRule="evenodd"
		/>
	</svg>
);
