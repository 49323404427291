import { useContext, useState } from 'react';

import { getBalance } from '@/api/get-balance';
import getSupplier from '@/api/get-supplier';
import { getUserInfo } from '@/api/get-user-info';
import { USER_LOGGED_KEY_NAME } from '@/auth/constants';
import { FeedbackContext } from '@/context/feeadback.context';
import useAsyncEffect from '@/hooks/use-async-effect';
import { Balance } from '@/types/balance';
import { CompanyType } from '@/types/common-entities';
import { UserContextType } from '@/types/context/user.context';
import { Supplier } from '@/types/supplier';
import { User } from '@/types/user';
import { LocalStorage } from '@/utils/local-storage';

export const useUserContext = () => {
	const { message } = useContext(FeedbackContext);
	const [userContext, setUserContext] = useState<Omit<UserContextType, 'updateUser'>>({
		user: null,
		balance: null,
		userCompany: null,
		initialDataLoading: true,
	});

	const setUser = (user: User) => {
		setUserContext((prev) => {
			return {
				...prev,
				user,
			};
		});
	};

	const setInitialDataLoading = (initialDataLoading: boolean) => {
		setUserContext((prev) => {
			return {
				...prev,
				initialDataLoading,
			};
		});
	};

	const setBalance = (balance: Balance) => {
		setUserContext(
			(prev) =>
				({
					...prev,
					balance,
				}) as UserContextType,
		);
	};

	const setUserCompany = (userCompany: Supplier) => {
		setUserContext((prev) => {
			return {
				...prev,
				userCompany,
			};
		});
	};

	const resetUserContext = () => {
		setUserContext({
			user: null,
			balance: null,
			userCompany: null,
			initialDataLoading: false,
		});
	};

	const updateUser = async (): Promise<User | null> => {
		try {
			const user = await getUserInfo();
			setUser(user);
			LocalStorage.setItem(USER_LOGGED_KEY_NAME, 'true');
			void updateUserInfo(user);
			return user;
		} catch (e) {
			console.error(e);
			// message.warning('Ошибка при получении пользователя');
			LocalStorage.removeItem(USER_LOGGED_KEY_NAME);
			return null;
		}
	};

	const updateUserCompany = async (user: User) => {
		try {
			const userCompany = await getSupplier(user.company_id);
			setUserCompany(userCompany);
		} catch (error) {
			console.error(error);
			message.warning('Ошибка при получении данных о компании пользователя.');
		}
	};

	const updateUserBalance = async () => {
		try {
			const balance = await getBalance();
			setBalance(balance);
		} catch (error) {
			console.error(error);
			message.warning('Ошибка при получении данных о балансе пользователя.');
		}
	};

	const updateUserInfo = async (user: User) => {
		if (!userContext.userCompany) {
			void updateUserCompany(user);
		}
		if (user?.company_type === CompanyType.FishCompany && !userContext.balance) {
			void updateUserBalance();
		}
	};

	const handleReset = (event: CustomEvent) => {
		if (event.detail.key === USER_LOGGED_KEY_NAME) {
			resetUserContext();
		}
	};
	const isLogged = () => LocalStorage.hasItem(USER_LOGGED_KEY_NAME);

	useAsyncEffect(async () => {
		LocalStorage.addEventListener('remove', handleReset);

		const isLogged = LocalStorage.hasItem(USER_LOGGED_KEY_NAME);
		let user: User;

		if (isLogged) {
			if (!userContext.user) {
				user = await updateUser();
			}
		}

		if (user && !userContext.userCompany) {
			void updateUserCompany(user);
		}

		if (user?.company_type === CompanyType.FishCompany && !userContext.balance) {
			void updateUserBalance();
		}

		if (!isLogged && userContext.user !== null) {
			resetUserContext();
		}
		setInitialDataLoading(false);
	}, []);

	return {
		isLogged,
		updateUser,
		userContext,
		resetUserContext,
	};
};
