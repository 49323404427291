import { FC } from 'react';

import { IconProps } from './types';

export const SkyscraperIcon: FC<IconProps> = ({ size, width = 20, height = 24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 24"
		width={size ?? width}
		height={size ?? height}
	>
		<path
			fill="#06519E"
			d="M4.369 5.4545c0 .6022.4943 1.091 1.0965 1.091.6021 0 1.0909-.4888 1.0909-1.091 0-.6021-.4888-1.0909-1.091-1.0909h-.0109c-.6021 0-1.0854.4888-1.0854 1.091ZM14.1928 6.5455c-.6033 0-1.0964-.4888-1.0964-1.091 0-.6021.4822-1.0909 1.0855-1.0909h.0109c.6032 0 1.0909.4888 1.0909 1.091 0 .6021-.4877 1.0909-1.091 1.0909ZM8.7327 5.4545c0 .6022.4942 1.091 1.0964 1.091.6022 0 1.0909-.4888 1.0909-1.091 0-.6021-.4887-1.0909-1.091-1.0909h-.0108c-.6022 0-1.0855.4888-1.0855 1.091ZM9.829 10.909c-.602 0-1.0963-.4886-1.0963-1.0908 0-.6022.4833-1.091 1.0855-1.091h.0109c.6022 0 1.0909.4888 1.0909 1.091s-.4887 1.0909-1.091 1.0909ZM8.7327 14.1818c0 .6033.4942 1.091 1.0964 1.091.6022 0 1.0909-.4877 1.0909-1.091s-.4887-1.0909-1.091-1.0909h-.0108c-.6022 0-1.0855.4876-1.0855 1.091ZM14.1928 10.909c-.6033 0-1.0964-.4886-1.0964-1.0908 0-.6022.4822-1.091 1.0855-1.091h.0109c.6032 0 1.0909.4888 1.0909 1.091s-.4877 1.0909-1.091 1.0909ZM13.0964 14.1818c0 .6033.493 1.091 1.0964 1.091a1.09 1.09 0 0 0 1.0909-1.091 1.09 1.09 0 0 0-1.091-1.0909h-.0108c-.6033 0-1.0855.4876-1.0855 1.091ZM5.4655 10.909c-.6022 0-1.0964-.4886-1.0964-1.0908 0-.6022.4833-1.091 1.0854-1.091h.011c.6021 0 1.0909.4888 1.0909 1.091s-.4888 1.0909-1.091 1.0909ZM4.369 14.1818c0 .6033.4943 1.091 1.0965 1.091.6021 0 1.0909-.4877 1.0909-1.091s-.4888-1.0909-1.091-1.0909h-.0109c-.6021 0-1.0854.4876-1.0854 1.091Z"
			fillRule="evenodd"
		/>
		<path
			fill="#06519E"
			d="M16.3636 24H3.2727C1.4684 24 0 22.5316 0 20.7273V3.2727C0 1.4684 1.4684 0 3.2727 0h13.091c1.8043 0 3.2727 1.4684 3.2727 3.2727v17.4546c0 1.8043-1.4684 3.2727-3.2728 3.2727Zm0-2.1818h-2.1818v-3.2727a1.09 1.09 0 0 0-1.0909-1.091H6.5455c-.6022 0-1.091.4877-1.091 1.091v3.2727H3.2727c-.601 0-1.0909-.4887-1.0909-1.091V3.2728c0-.601.4898-1.0909 1.091-1.0909h13.0908c.6022 0 1.091.4898 1.091 1.091v17.4545c0 .6022-.4888 1.0909-1.091 1.0909ZM12 19.6364H7.6364v2.1818H12v-2.1818Z"
			fillRule="evenodd"
		/>
	</svg>
);
