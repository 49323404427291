export const LocationIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="11" height="13">
		<path
			fill="#0A2653"
			d="M2.917 5.25c0 1.287 1.046 2.333 2.333 2.333 1.287 0 2.333-1.046 2.333-2.333 0-1.287-1.046-2.333-2.333-2.333-1.287 0-2.333 1.046-2.333 2.333Zm1.166 0c0-.643.524-1.167 1.167-1.167s1.167.524 1.167 1.167-.524 1.167-1.167 1.167-1.167-.524-1.167-1.167Z"
		/>
		<path
			fill="#0A2653"
			d="M4.9 12.717c.104.078.227.116.35.116.123 0 .246-.038.35-.116.2-.15 4.9-3.719 4.9-7.467C10.5 2.355 8.145 0 5.25 0S0 2.355 0 5.25c0 3.748 4.7 7.317 4.9 7.467ZM1.167 5.25c0-2.252 1.831-4.083 4.083-4.083 2.251 0 4.083 1.831 4.083 4.083 0 2.632-3.035 5.382-4.083 6.253-1.048-.871-4.083-3.621-4.083-6.253Z"
		/>
	</svg>
);
