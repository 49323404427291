export const LeftArrow = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
			<path
				fill="currentColor"
				transform="translate(0.0938721 0.0977783)"
				d="M7.2931352 15.70725C7.4881353 15.90225 7.7441354 16.00025 8.0001354 16.00025C8.256135 16.00025 8.5121355 15.90225 8.7071352 15.70725C9.098135 15.31625 9.098135 14.68425 8.7071352 14.29325L3.4141352 9.0002499L15.000135 9.0002499C15.553135 9.0002499 16.000135 8.5522499 16.000135 8.0002499C16.000135 7.4482498 15.553135 7.0002499 15.000135 7.0002499L3.4141352 7.0002499L8.7071352 1.70725C9.098135 1.31625 9.098135 0.68425 8.7071352 0.29324999C8.3161354 -0.097750001 7.6841354 -0.097750001 7.2931352 0.29324999L0.29513529 7.2912502C0.29013526 7.2962499 0.28513527 7.30125 0.28013527 7.3062501C-0.090864725 7.6922498 -0.093864724 8.2992496 0.27413526 8.6872501C0.28113526 8.6942501 0.28813529 8.7022495 0.29513529 8.7092505L7.2931352 15.70725Z"
			/>
		</svg>
	);
};
