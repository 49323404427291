import { FC, useContext } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { Button, Dropdown, Flex } from 'antd';
import classNames from 'classnames';

import { UserContext } from '@/context/user.context';
import useMobile from '@/hooks/use-mobile';
import { useTranslate } from '@/hooks/use-translate';
import { CompanyType } from '@/types/common-entities';
import { MenuIcon, PhoneIcon } from '@/UI/icons';
import { CloseOutlined } from '@ant-design/icons';

import { LangSelect } from '../LangSelect';

import { AccountButton } from './AccountButton';
import { en } from './en';
import { Logo } from './Logo';

import styles from './Header.module.scss';

type Props = {
	showMobileMenu: boolean;
	setShowMobileMenu: (value: React.SetStateAction<boolean>) => void;
};

export const Header: FC<Props> = ({ showMobileMenu, setShowMobileMenu }) => {
	const t = useTranslate(en);
	const router = useRouter();
	const { user } = useContext(UserContext);
	const { locale } = router;
	const isMobile = useMobile();

	const showForWarehouse = !user || user?.company_type === CompanyType.Warehouse;
	const showForFC = !user || user?.company_type === CompanyType.FishCompany;

	if (isMobile)
		return (
			<Flex align="center" justify="center" className={classNames(styles.header)} id="app-header">
				<Logo locale={locale} />
				<div className={styles.toggleMenu}>
					<Button
						onClick={() => setShowMobileMenu((prev) => !prev)}
						type="text"
						icon={showMobileMenu ? <CloseOutlined /> : <MenuIcon />}
					/>
				</div>
			</Flex>
		);

	return (
		<Flex
			align="center"
			className={classNames(styles.header, { [styles.userHeader]: !!user })}
			id="app-header"
		>
			<Logo locale={locale} />
			<Flex align="center" justify="space-between" style={{ width: '100%' }}>
				<ul>
					{showForFC && (
						<li>
							<Link
								className={classNames('whitespace-nowrap', styles.headerItem)}
								href="/buy-fish"
								locale={locale}
							>
								{t('Купить рыбу')}
							</Link>
						</li>
					)}
					{showForFC && (
						<li>
							<Link
								className={classNames('whitespace-nowrap', styles.headerItem)}
								href={user ? '/personal-account/fish-products' : '/login'}
								locale={locale}
							>
								{t('Продать рыбу')}
							</Link>
						</li>
					)}
					<li>
						<Link
							className={classNames('whitespace-nowrap', styles.headerItem)}
							href="/depositor-company"
							locale={locale}
						>
							{t(`${user ? 'Найти склад' : 'Рыбной компании'}`)}
						</Link>
					</li>
					{showForWarehouse && (
						<li>
							<Link
								className={classNames('whitespace-nowrap', styles.headerItem)}
								href="/storage"
								locale={locale}
							>
								{t('Складу')}
							</Link>
						</li>
					)}
					<li>
						<Dropdown
							trigger={['click']}
							arrow={false}
							placement="bottomLeft"
							overlayClassName={styles.dropdown}
							menu={{
								items: [
									{
										key: 'about',
										label: t('О платформе'),
										onClick: () => router.push('/about-us'),
									},
									{
										key: 'helpCenter',
										label: t('Справочный центр'),
										onClick: () => router.push('/help-center'),
									},
								],
							}}
						>
							<span className={classNames(styles.headerItem, styles.accentItem)}>
								{t('Платформа')}
							</span>
						</Dropdown>
					</li>
				</ul>
				<Flex className={styles.actions} align="center">
					<a
						href="tel:+79089998080"
						className={classNames(styles.headerItem, styles.accentItem, styles.telephone)}
					>
						<Flex align="center" gap={8}>
							<PhoneIcon />
							+7 908 999 80 80
						</Flex>
					</a>
					<LangSelect />
					<AccountButton />
				</Flex>
			</Flex>
		</Flex>
	);
};
