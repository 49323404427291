import { useContext } from 'react';

import { useRouter } from 'next/router';

import { Button, Dropdown, Flex, message } from 'antd';
import classNames from 'classnames';

import logout from '@/api/auth/logout';
import { REDIRECT_TO_QUERY } from '@/constants/authorization';
import { AvoidRedirectList } from '@/constants/common';
import { UserContext } from '@/context/user.context';
import { useTranslate } from '@/hooks/use-translate';
import { CompanyType } from '@/types/common-entities';

import { en } from './en';

import styles from './Header.module.scss';

export const AccountButton = () => {
	const { user, userCompany } = useContext(UserContext);
	const router = useRouter();
	const t = useTranslate(en);

	const onExit = async () => {
		try {
			await logout();
			void router.push('/login', '/login', { shallow: true });
		} catch (error) {
			message.error('Ошибка при попытке выйти из личного кабинета. Попробуйте обновить страницу.');
		}
	};

	const getLoginPath = () => {
		if (!AvoidRedirectList.some((path) => router.pathname.includes(path))) {
			return `/login?${REDIRECT_TO_QUERY}=${router.asPath}`;
		} else if (router.query[REDIRECT_TO_QUERY]) {
			return `/login?${REDIRECT_TO_QUERY}=${router.query[REDIRECT_TO_QUERY]}`;
		}
		return '/login';
	};

	return user ? (
		<Dropdown
			trigger={['click']}
			arrow={false}
			placement="bottomRight"
			overlayClassName={styles.dropdown}
			menu={{
				items: [
					{
						key: 'account',
						label: t('Личный кабинет'),
						onClick: () =>
							router.push(
								user.company_type === CompanyType.FishCompany
									? '/personal-account'
									: '/storage-account',
							),
					},
					{
						key: 'office',
						label: t('Цифровой офис'),
						onClick: () => router.push(`/office/${user.digital_office}`),
					},
					{
						key: 'exit',
						label: t('Выход'),
						onClick: onExit,
					},
				],
			}}
		>
			<div className={classNames(styles.account, styles.accentItem)}>
				<Flex vertical justify="space-between">
					<div className={styles.accountTitle}>{t('Личный кабинет')}</div>
					<div className={styles.accountText}>{userCompany?.title_short ?? '—'}</div>
				</Flex>
			</div>
		</Dropdown>
	) : (
		<Button href={getLoginPath()} size="small" className={styles.loginBtn} type="primary">
			Войти
		</Button>
	);
};
