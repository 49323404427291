export const LaungaryIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
		<path
			fill="#06519E"
			d="M9 15c-1.103 0-2-.897-2-2V7c0-1.103.897-2 2-2h2V4c0-1.654 1.346-3 3-3h1c1.654 0 3 1.346 3 3v1h2c1.103 0 2 .897 2 2v6c0 1.103-.897 2-2 2H9Zm7-10V4c0-.551-.448-1-1-1h-1c-.552 0-1 .449-1 1v1h3Zm-3 2h3v6h-3V7Zm-2 0H8.999L9 13h2V7Zm7 0h2v6h-2V7Z"
		/>
		<path
			fill="#06519E"
			d="M6 19c-1.654 0-3-1.346-3-3V7c0-.551-.449-1-1-1-.552 0-1-.448-1-1s.448-1 1-1c1.654 0 3 1.346 3 3v9c0 .552.449 1 1 1h16c.553 0 1 .447 1 1 0 .553-.447 1-1 1h-1.171c.111.313.171.65.171 1 0 1.654-1.346 3-3 3s-3-1.346-3-3c0-.35.06-.687.171-1h-3.342c.111.313.171.65.171 1 0 1.654-1.346 3-3 3s-3-1.346-3-3c0-.35.06-.687.171-1H6Zm3 0c-.551 0-1 .448-1 1s.449 1 1 1c.551 0 1-.448 1-1s-.449-1-1-1Zm8 1c0-.552.448-1 1-1s1 .448 1 1-.448 1-1 1-1-.448-1-1Z"
		/>
	</svg>
);
