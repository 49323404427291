import logout from '@/api/auth/logout';
import {
	BoxIcon,
	CardIcon,
	CargoIcon,
	CubesIcon,
	DocumentsIcon,
	ExitIcon,
	FishIcon,
	FishPlusIcon,
	FishWithBubbleIcon,
	FormIcon,
	HomeIcon,
	InformationEmptyIcon,
	OpenLockIcon,
	SkyscraperIcon,
	SnowflakeIcon,
} from '@/UI/icons';

import { MobileMenuItem } from './MobileMenu.types';

const lkIconSize = 16;

export const getWarehouseItems = (): MobileMenuItem[] => [
	{
		link: '/',
		icon: <HomeIcon />,
		text: 'Главная',
	},
	{
		link: '/depositor-company',
		icon: <SnowflakeIcon />,
		text: 'Найти склад',
	},
	{
		link: '/storage',
		icon: <BoxIcon />,
		text: 'Складу',
	},
	{
		link: '/about-us',
		icon: <InformationEmptyIcon />,
		text: 'О платформе',
	},
];

export const getWarehouseLkItems = (): MobileMenuItem[] => [
	{
		link: '/storage-account',
		icon: <CubesIcon size={lkIconSize} />,
		text: 'Рабочий стол',
	},
	{
		link: '/storage-account/applications',
		icon: <DocumentsIcon size={lkIconSize} />,
		text: 'Заявки',
	},
	{
		link: '/storage-account/suppliers',
		icon: <CargoIcon size={lkIconSize} />,
		text: 'Поклажедатели',
	},
	{
		link: '/storage-account/fish-products',
		icon: <FishIcon size={lkIconSize} />,
		text: 'Рыбопродукция',
	},
	{
		link: '/storage-account/additional-services',
		icon: <CardIcon size={lkIconSize} />,
		text: 'Доп. услуги',
	},
	{
		link: '/storage-account/refrigerators',
		icon: <CardIcon size={lkIconSize} />,
		text: 'Холодильники',
	},
	{
		link: '/storage-account/settings',
		icon: <FormIcon size={lkIconSize} />,
		text: 'Профиль склада',
	},
	{
		icon: <ExitIcon size={lkIconSize} />,
		text: 'Выйти',
		onClick: async () => {
			await logout();
		},
	},
];

export const getUnauthorizedItems = (): MobileMenuItem[] => [
	{
		link: '/login',
		background: '#06519E',
		icon: <OpenLockIcon />,
		text: 'Вход / Регистрация',
	},
	{
		link: '/',
		icon: <HomeIcon />,
		text: 'Главная',
	},
	{
		link: '/buy-fish',
		icon: <FishPlusIcon />,
		text: 'Купить рыбу',
	},
	{
		link: '/sell-fish',
		icon: <FishWithBubbleIcon />,
		text: 'Продать рыбу',
	},
	{
		link: '/depositor-company',
		icon: <SnowflakeIcon />,
		text: 'Рыбной компании',
	},
	{
		link: '/storage',
		icon: <BoxIcon />,
		text: 'Складу',
	},
	{
		link: '/about-us',
		icon: <InformationEmptyIcon />,
		text: 'О платформе',
	},
];

export const getFishCompanyItems = (): MobileMenuItem[] => [
	{
		link: '/',
		icon: <HomeIcon />,
		text: 'Главная',
	},
	{
		link: '/buy-fish',
		icon: <FishPlusIcon />,
		text: 'Купить рыбу',
	},
	{
		link: '/sell-fish',
		icon: <FishWithBubbleIcon />,
		text: 'Продать рыбу',
	},
	{
		link: '/depositor-company',
		icon: <SnowflakeIcon />,
		text: 'Найти склад',
	},
	{
		link: '/about-us',
		icon: <InformationEmptyIcon />,
		text: 'О платформе',
	},
];

export const getFishCompanyLkItems = (digital_office: string): MobileMenuItem[] => [
	{
		link: '/personal-account',
		icon: <CubesIcon size={lkIconSize} />,
		text: 'Рабочий стол',
	},
	{
		link: '/personal-account/warehouses',
		icon: <CargoIcon height={14} width={lkIconSize} />,
		text: 'Склады',
	},
	{
		link: '/personal-account/applications',
		icon: <DocumentsIcon size={lkIconSize} />,
		text: 'Заявки',
	},
	{
		link: '/personal-account/fish-products',
		icon: <FishIcon height={12} width={lkIconSize} />,
		text: 'Рыбопродукция',
	},
	{
		link: '/personal-account/payments',
		icon: <CardIcon height={11.6} width={lkIconSize} />,
		text: 'Оплата и подписка',
	},
	{
		link: '/personal-account/settings',
		icon: <FormIcon size={lkIconSize} />,
		text: 'Профиль компании',
	},
	{
		link: `${window.location.origin}/office/${digital_office}`,
		icon: <SkyscraperIcon size={lkIconSize} />,
		text: 'Цифровой офис',
	},
	{
		icon: <ExitIcon size={lkIconSize} />,
		text: 'Выйти',
		onClick: async () => {
			await logout();
		},
	},
];
