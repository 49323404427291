import { FC, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Flex, List } from 'antd';

import { UserContext } from '@/context/user.context';
import useMobile from '@/hooks/use-mobile';
import { useTranslate } from '@/hooks/use-translate';
import { CompanyType } from '@/types/common-entities';
import { PhoneIcon } from '@/UI/icons';

import { LangSelect } from '../LangSelect';

import { en } from './en';
import { ListItem } from './ListItem';
import {
	getFishCompanyItems,
	getFishCompanyLkItems,
	getUnauthorizedItems,
	getWarehouseItems,
	getWarehouseLkItems,
} from './MobileMenu.config';
import { MobileMenuItem } from './MobileMenu.types';
import { getLkInfo } from './MobileMenu.utils';

import styles from './MobileMenu.module.scss';

type Props = {
	open: boolean;
	onClose: () => void;
};

export const MobileMenu: FC<Props> = ({ open, onClose }) => {
	const isMobile = useMobile();
	const userInfo = useContext(UserContext);
	const router = useRouter();
	const [showLkList, setShowLkList] = useState(false);
	const t = useTranslate(en);

	const inLk =
		router.pathname.includes('/personal-account') || router.pathname.includes('/storage-account');

	useEffect(() => setShowLkList(inLk), [inLk]);

	useEffect(() => {
		if (!userInfo.user) setShowLkList(false);
	}, [userInfo.user]);

	if (!isMobile || !open) return <></>;

	const getListItems = () => {
		if (!userInfo.user) return getUnauthorizedItems();
		else {
			if (userInfo.user.company_type === CompanyType.FishCompany) return getFishCompanyItems();
			else return getWarehouseItems();
		}
	};

	const getLkListItems = () => {
		if (!userInfo.user) return [];

		return userInfo.user.company_type === CompanyType.FishCompany
			? getFishCompanyLkItems(userInfo.user.digital_office)
			: getWarehouseLkItems();
	};

	const lkInfo = getLkInfo(router, userInfo.user, userInfo.userCompany);

	return (
		<div className={styles.container}>
			<Flex
				justify="space-between"
				className={styles.info}
				align="center"
				onClick={() => {
					if (userInfo.user) setShowLkList((prev) => !prev);
					else {
						router.push(lkInfo.link);
						onClose();
					}
				}}
			>
				<div>
					<div className={styles.infoStatus}>{t(lkInfo.mainText)}</div>
					<div className={styles.infoDescription}>
						{userInfo.user ? lkInfo.extraText : t(lkInfo.extraText)}
					</div>
				</div>
				<LangSelect additionalClass={styles.langSelect} />
			</Flex>
			{showLkList && (
				<List<MobileMenuItem>
					dataSource={getLkListItems().map((item) => ({ ...item, text: t(item.text) }))}
					size="small"
					renderItem={(item) => <ListItem {...item} onClose={onClose} itemClass={styles.lkItem} />}
				/>
			)}
			<List<MobileMenuItem>
				dataSource={getListItems().map((item) => ({ ...item, text: t(item.text) }))}
				renderItem={(item) => <ListItem {...item} onClose={onClose} itemClass={styles.item} />}
			/>
			<Flex className={styles.telephone} justify="center" align="center">
				<Flex gap={8} align="center">
					<PhoneIcon /> <a href="tel:+7 908 999 80 80">+7 908 999 80 80</a>
				</Flex>
			</Flex>
			<Flex className={styles.email} justify="center" align="center">
				<a href="mailto:hello@fishplace.ru">hello@fishplace.ru</a>
			</Flex>
		</div>
	);
};
