import { FC, PropsWithChildren } from 'react';

import Link from 'next/link';

import { Flex } from 'antd';

import { MobileMenuItem } from './MobileMenu.types';

import styles from './MobileMenu.module.scss';

type Props = MobileMenuItem & { onClose: () => void; itemClass: string };

export const ListItem: FC<Props> = (props) => {
	const { link, onClose, onClick } = props;

	if (link)
		return (
			<Link onClick={onClose} className={styles.link} href={link}>
				<ItemInner {...props} />
			</Link>
		);

	return (
		<div
			onClick={() => {
				onClick();
				onClose();
			}}
		>
			<ItemInner {...props} />
		</div>
	);
};

export const ItemInner: FC<Props> = (props) => {
	const { background, icon, text, itemClass } = props;
	return (
		<Flex
			gap={24}
			className={itemClass}
			align="center"
			style={background && { background: background, color: 'white' }}
		>
			<div className={styles.icon} style={{ color: background ? 'white' : '#06519E' }}>
				{icon}
			</div>
			<div>{text}</div>
		</Flex>
	);
};
