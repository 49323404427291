import { GetProps } from 'antd';

import Icon from '@ant-design/icons';

type CustomIconComponentProps = GetProps<typeof Icon>;

const IconSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16">
		<path
			fill="currentColor"
			transform="translate(5.09091 6.54545)"
			d="M0.72727275 5.818182C0.32581818 5.818182 0 5.4930911 0 5.090909L0 0.72727275C0 0.32581818 0.32581818 0 0.72727275 0C1.1287273 0 1.4545455 0.32581818 1.4545455 0.72727275L1.4545455 5.090909C1.4545455 5.4930911 1.1287273 5.818182 0.72727275 5.818182Z"
		/>
		<path
			fill="currentColor"
			transform="translate(8 6.54545)"
			d="M0 5.090909C0 5.4930911 0.32509091 5.818182 0.72727275 5.818182C1.1294545 5.818182 1.4545455 5.4930911 1.4545455 5.090909L1.4545455 0.72727275C1.4545455 0.32581818 1.1294545 0 0.72727275 0C0.32509091 0 0 0.32581818 0 0.72727275L0 5.090909Z"
		/>
		<path
			fill="currentColor"
			d="M3.6363637 2.909091L3.6363637 2.1818182C3.6363637 1.0603637 4.6967273 0 5.818182 0L8.727273 0C9.8487272 0 10.909091 1.0603637 10.909091 2.1818182L10.909091 2.909091L13.818182 2.909091C14.220364 2.909091 14.545455 3.2349091 14.545455 3.6363637C14.545455 4.037818 14.220364 4.3636365 13.818182 4.3636365L13.090909 4.3636365L13.090909 13.818182C13.090909 14.939636 12.030545 16 10.909091 16L3.6363637 16C2.514909 16 1.4545455 14.939636 1.4545455 13.818182L1.4545455 4.3636365L0.72727275 4.3636365C0.32581818 4.3636365 0 4.037818 0 3.6363637C0 3.2349091 0.32581818 2.909091 0.72727275 2.909091L3.6363637 2.909091ZM2.909091 4.3636365L2.909091 13.818182C2.909091 14.144 3.3098183 14.545455 3.6363637 14.545455L10.909091 14.545455C11.234909 14.545455 11.636364 14.144 11.636364 13.818182L11.636364 4.3636365L2.909091 4.3636365ZM9.454545 2.1818182L9.454545 2.909091L5.090909 2.909091L5.090909 2.1818182C5.090909 1.8552728 5.4916363 1.4545455 5.818182 1.4545455L8.727273 1.4545455C9.053091 1.4545455 9.454545 1.8552728 9.454545 2.1818182Z"
			fillRule="evenodd"
		/>
	</svg>
);

export const TrashIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={IconSvg} {...props} />
);
