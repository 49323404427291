import { RequestService } from '@/api/services/request.service';

export enum AutoOpen {
	Once = 'once',
	Never = 'never',
	Always = 'always',
}

export type VideoTutorialsResponse = {
	id: string;
	created: string;
	updated: string;
	title: string;
	description?: string;
	pageUrl: string;
	videoUrl: string;
	autoOpen: AutoOpen;
};

export const getVideoTutorials = async (pageUrl: string): Promise<VideoTutorialsResponse> => {
	const request = RequestService.getInstance();
	try {
		const { data } = await request.get(
			`${process.env.HOST}/api_v1/video-tutorial?page-url=${pageUrl}`,
		);
		return data;
	} catch (error) {
		throw new Error(
			error.response?.data?.message ?? error?.statusText ?? 'Ошибка при получении видео инструкций',
		);
	}
};
