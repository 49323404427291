import { FC } from 'react';

import { useRouter } from 'next/router';

import { Dropdown, Flex } from 'antd';
import classNames from 'classnames';

import { useTranslate } from '@/hooks/use-translate';
import { GlobeIcon } from '@/UI/icons';

import { en } from './en';

import styles from './LangSelect.module.scss';

type Props = {
	additionalClass?: string;
};

export const LangSelect: FC<Props> = ({ additionalClass }) => {
	const router = useRouter();
	const t = useTranslate(en);
	const { locale } = router;

	const onChangeLocale = (locale: 'ru' | 'en') => {
		if (router.locale != locale) router.push(router.asPath, router.asPath, { locale });
	};

	return (
		<Dropdown
			trigger={['click']}
			arrow={false}
			placement="bottomRight"
			overlayClassName={styles.localeDropdown}
			menu={{
				items: [
					{ label: 'Русский', key: 'ru', onClick: () => onChangeLocale('ru') },
					{ label: 'English', key: 'en', onClick: () => onChangeLocale('en') },
				],
			}}
		>
			<Flex
				className={classNames(styles.item, additionalClass)}
				gap={8}
				align="center"
				onClick={(e) => e.stopPropagation()}
			>
				<GlobeIcon />
				{locale == 'ru' ? 'RUS' : 'ENG'}
			</Flex>
		</Dropdown>
	);
};
