import { FC } from 'react';

import { IconProps } from './types';

export const FormIcon: FC<IconProps> = ({ size, height = 24, width = 24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={size ?? width}
		height={size ?? height}
	>
		<path
			fill="#06519E"
			d="M16.1818 12h-4.3636c-.6022 0-1.0909-.4887-1.0909-1.091 0-.602.4887-1.0908 1.091-1.0908h4.3635c.6033 0 1.091.4887 1.091 1.0909S16.785 12 16.1818 12ZM11.8182 17.4545h4.3636a1.09 1.09 0 0 0 1.091-1.0909 1.09 1.09 0 0 0-1.091-1.0909h-4.3636c-.6022 0-1.0909.4876-1.0909 1.091 0 .6032.4887 1.0908 1.091 1.0908ZM7.4655 12c-.6022 0-1.0964-.4887-1.0964-1.091 0-.602.4833-1.0908 1.0854-1.0908h.011c.6021 0 1.0909.4887 1.0909 1.0909S8.0676 12 7.4654 12ZM6.369 16.3636c0 .6033.4943 1.091 1.0965 1.091.6021 0 1.0909-.4877 1.0909-1.091s-.4888-1.0909-1.091-1.0909h-.0109c-.6021 0-1.0854.4876-1.0854 1.091Z"
			fillRule="evenodd"
		/>
		<path
			fill="#06519E"
			d="M6.3636 2.1818C6.3636.9785 7.3422 0 8.5455 0h6.5454c1.2033 0 2.1818.9785 2.1818 2.1818h1.091c1.8043 0 3.2727 1.4684 3.2727 3.2727v15.2728c0 1.8043-1.4684 3.2727-3.2728 3.2727H5.2727C3.4684 24 2 22.5316 2 20.7273V5.4545c0-1.8043 1.4684-3.2727 3.2727-3.2727h1.091Zm2.1819 4.3637h6.5454c1.2033 0 2.1818-.9786 2.1818-2.1819h1.091c.6021 0 1.0908.4899 1.0908 1.091v15.2727c0 .6022-.4887 1.0909-1.0909 1.0909H5.2727c-.601 0-1.0909-.4887-1.0909-1.091V5.4546c0-.601.4898-1.0909 1.091-1.0909h1.0908c0 1.2033.9786 2.1819 2.1819 2.1819Zm-.0006-3.3081-.0005-1.0556h6.5465v2.1818H8.5455L8.5449 3.307a1.1166 1.1166 0 0 0 0-.0696Z"
			fillRule="evenodd"
		/>
	</svg>
);
