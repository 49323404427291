import { FC } from 'react';

import { IconProps } from './types';

export const CubesIcon: FC<IconProps> = ({ size, height = 24, width = 24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={`${size ?? width}`}
		height={`${size ?? height}`}
	>
		<path
			fill="#06519E"
			d="M1.2 10.8h8.4c.6627 0 1.2-.5373 1.2-1.2V1.2c0-.6627-.5373-1.2-1.2-1.2H1.2C.5373 0 0 .5373 0 1.2v8.4c0 .6627.5373 1.2 1.2 1.2Zm7.2-2.4h-6v-6h6v6Zm6 2.4h8.4c.6627 0 1.2-.5373 1.2-1.2V1.2c0-.6627-.5373-1.2-1.2-1.2h-8.4c-.6627 0-1.2.5373-1.2 1.2v8.4c0 .6627.5373 1.2 1.2 1.2Zm7.2-2.4h-6v-6h6v6ZM22.8 24h-8.4c-.6627 0-1.2-.5373-1.2-1.2v-8.4c0-.6627.5373-1.2 1.2-1.2h8.4c.6627 0 1.2.5373 1.2 1.2v8.4c0 .6627-.5373 1.2-1.2 1.2Zm-7.2-2.4h6v-6h-6v6ZM1.2 24h8.4c.6627 0 1.2-.5373 1.2-1.2v-8.4c0-.6627-.5373-1.2-1.2-1.2H1.2c-.6627 0-1.2.5373-1.2 1.2v8.4c0 .6627.5373 1.2 1.2 1.2Zm7.2-2.4h-6v-6h6v6Z"
			fillRule="evenodd"
		/>
	</svg>
);
