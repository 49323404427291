export const ShareIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
		<path
			fill="currentColor"
			d="M9.1397 13.5438c1.178 1.57 2.984 2.4 4.813 2.4 1.25 0 2.509-.39 3.586-1.19.229-.17.447-.36.65-.56l3.013-3.01c2.298-2.38 2.232-6.19-.148-8.49-2.337-2.26-6-2.26-8.347.01l-1.72 1.71c-.392.39-.394 1.02-.004 1.42.389.39 1.022.39 1.414 0l1.709-1.7c1.559-1.51 4-1.51 5.559 0 1.586 1.53 1.63 4.07.11 5.64l-3 3c-.135.14-.279.26-.433.38-1.768 1.32-4.278.96-5.6-.81-.33-.44-.958-.53-1.4-.2-.442.33-.533.96-.202 1.4Z"
			fillRule="evenodd"
		/>
		<path
			fill="currentColor"
			d="M2.8287 21.1937c1.168 1.13 2.664 1.69 4.164 1.69 1.503 0 3.009-.57 4.186-1.7l1.71-1.71c.391-.39.391-1.03 0-1.42-.391-.39-1.023-.39-1.414 0l-1.698 1.7c-1.558 1.51-4 1.51-5.557 0-1.587-1.53-1.631-4.07-.111-5.64l3-3c.136-.14.281-.26.434-.38.855-.64 1.91-.91 2.966-.75 1.057.15 1.992.7 2.632 1.56.33.44.956.53 1.399.2.442-.33.533-.96.202-1.4-.96-1.28-2.362-2.12-3.948-2.34-1.59-.23-3.167.17-4.45 1.13-.23.17-.447.36-.65.56l-3.012 3.01c-2.299 2.38-2.232 6.19.147 8.49Z"
			fillRule="evenodd"
		/>
	</svg>
);
