import { FC } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

type Props = {
	locale: string;
};

export const Logo: FC<Props> = ({ locale }) => {
	const router = useRouter();
	const onMainPage = router.route === '/';
	return (
		<div style={{ cursor: onMainPage ? 'default' : 'pointer' }}>
			<Image
				src={`/img/${locale === 'ru' ? 'logo' : 'logo-en'}.svg`}
				alt={locale === 'ru' ? 'Логотип FishPlace' : 'Fisplace logo'}
				width={220}
				height={32}
				quality={100}
				onClick={() => !onMainPage && router.push('/')}
				priority
			/>
		</div>
	);
};
