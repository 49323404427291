import { FC, useContext, useState } from 'react';

import { useRouter } from 'next/router';

import { FloatButton, Modal, Spin } from 'antd';
import classNames from 'classnames';

import {
	AutoOpen,
	getVideoTutorials,
	VideoTutorialsResponse,
} from '@/api/video-tutorials/video-tutorials';
import { UserContext } from '@/context/user.context';
import useAsyncEffect from '@/hooks/use-async-effect';
import useMobile from '@/hooks/use-mobile';

import { QuestionIcon } from './icon';

import styles from './VideoHelper.module.scss';

export const VideoHelper: FC = () => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [videoData, setVideoData] = useState<VideoTutorialsResponse>(null);
	const isMobile = useMobile();

	const { user } = useContext(UserContext);
	const router = useRouter();

	const tutorialKey = `${router.pathname}-${user?.id}`;

	useAsyncEffect(async () => {
		setIsOpenModal(false);
		try {
			const data = await getVideoTutorials(router.pathname === '/' ? '/home' : router.pathname);
			setVideoData(data);

			if (data.autoOpen === AutoOpen.Always) {
				setIsOpenModal(true);
			}

			if (data.autoOpen === AutoOpen.Once) {
				const updatedConfig: string = localStorage.getItem(tutorialKey);

				if (!updatedConfig || updatedConfig !== data.updated) {
					setIsOpenModal(true);
				}
			}
		} catch (e) {
			console.error(e);
			setVideoData(null);
		} finally {
			setIsLoading(false);
		}
	}, [router.pathname]);

	if (!user) {
		return null;
	}

	if (isLoading) {
		return <Spin />;
	}

	if (!isOpenModal && videoData) {
		return (
			<FloatButton
				data-testid="video-helper"
				type="primary"
				icon={<QuestionIcon />}
				tooltip="Инструкция"
				style={{
					left: isMobile ? 12 : 48,
					bottom: 12,
					display: 'block',
				}}
				onClick={() => setIsOpenModal(true)}
				className={styles.floatButtonBody}
				rootClassName={styles.floatButton}
			/>
		);
	}

	return (
		<Modal
			title={<div className={styles.title}>{videoData?.title}</div>}
			open={isOpenModal}
			onCancel={() => {
				localStorage.setItem(tutorialKey, videoData?.updated);

				setIsOpenModal(false);
			}}
			rootClassName={styles.modal}
			width={1080}
			height="auto"
			footer={false}
			maskClosable={false}
		>
			<div className={styles.description}>{videoData?.description}</div>
			<iframe
				width="100%"
				height={isMobile ? 'auto' : 600}
				src={videoData?.videoUrl}
				frameBorder="0"
				allow="clipboard-write; autoplay"
				// @ts-ignore
				webkitAllowFullScreen
				mozallowfullscreen
				allowFullScreen
			/>
		</Modal>
	);
};
