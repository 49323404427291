export const en = {
	Главная: 'Home',
	'Найти склад': 'Find warehouse',
	Складу: 'Warehouse',
	'О платформе': 'About platform',
	'Рабочий стол': 'Desktop',
	Заявки: 'Applications',
	Поклажедатели: 'Transporters',
	Рыбопродукция: 'Fish products',
	'Доп. услуги': 'Extra services',
	Холодильники: 'Refrigerators',
	'Профиль склада': 'Warehouse profile',
	Выйти: 'Logout',
	'Вход / Регистрация': 'Login / Registration',
	'Купить рыбу': 'Buy fish',
	'Продать рыбу': 'Sell fish',
	'Рыбной компании': 'For fish company',
	Склады: 'Warehouses',
	'Оплата и подписка': 'Payment and subscription',
	'Профиль компании': 'Company profile',
	'Цифровой офис': 'Digital office',
	'Личный кабинет': 'Account',
	Авторизуйтесь: 'Authorize',
	'для покупки и продажи рыбопродукции': 'to buy and sell fish products',
};
