import { FC } from 'react';

import { IconProps } from './types';

export const MenuIcon: FC<IconProps> = ({ size, height = 14, width = 18 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 18 14"
		width={size ?? width}
		height={size ?? height}
	>
		<path
			fill="#06519E"
			d="M17 2H1c-.552 0-1-.45-1-1s.448-1 1-1h16c.553 0 1 .45 1 1s-.447 1-1 1Zm0 6H1c-.552 0-1-.45-1-1s.448-1 1-1h16c.553 0 1 .45 1 1s-.447 1-1 1ZM1 14h16c.553 0 1-.45 1-1s-.447-1-1-1H1c-.552 0-1 .45-1 1s.448 1 1 1Z"
			fillRule="evenodd"
		/>
	</svg>
);
