import { FC } from 'react';

import { IconProps } from './types';

export const ExitIcon: FC<IconProps> = ({ size, height = 24, width = 25 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 25 24"
		width={size ?? width}
		height={size ?? height}
	>
		<path
			fill="#06519E"
			d="M8.3999 24h-4.8C1.6153 24 0 22.38 0 20.4V3.6C0 1.62 1.6152 0 3.6 0h4.7999c.6624 0 1.2.54 1.2 1.2 0 .66-.5376 1.2-1.2 1.2h-4.8c-.6611 0-1.2.54-1.2 1.2v16.8c0 .66.5389 1.2 1.2 1.2h4.8c.6624 0 1.2.54 1.2 1.2 0 .66-.5376 1.2-1.2 1.2Z"
			fillRule="evenodd"
		/>
		<path
			fill="#06519E"
			d="M16.7998 19.2c-.3072 0-.6144-.12-.8484-.348-.4692-.468-.4692-1.236 0-1.704l3.9515-3.948H8.4c-.6624 0-1.2-.54-1.2-1.2 0-.66.5376-1.2 1.2-1.2h11.503l-3.9515-3.948c-.4692-.468-.4692-1.236 0-1.704.4692-.468 1.2276-.468 1.6968 0l5.9807 5.988c.0936.084.174.192.2352.312.1836.348.1812.768-.0072 1.116-.06.12-.1368.216-.228.3l-5.9807 5.988c-.234.228-.5412.348-.8484.348Z"
			fillRule="evenodd"
		/>
	</svg>
);
