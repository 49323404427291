export const InformationEmptyIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
		<path
			fill="#06519E"
			d="M12 17.4546c-.6022 0-1.0909-.491-1.0909-1.091V12c0-.6.4887-1.0909 1.091-1.0909.602 0 1.0908.491 1.0908 1.091v4.3635c0 .6-.4887 1.091-1.0909 1.091ZM10.9145 7.6364c0 .6.4942 1.0909 1.0964 1.0909.6021 0 1.0909-.491 1.0909-1.091s-.4888-1.0909-1.091-1.0909H12c-.6022 0-1.0855.491-1.0855 1.091Z"
		/>
		<path
			fill="#06519E"
			d="M12 24C5.3836 24 0 18.6218 0 12S5.3836 0 12 0s12 5.3782 12 12-5.3836 12-12 12Zm0-21.8182c-5.413 0-9.8182 4.4073-9.8182 9.8182 0 5.411 4.4051 9.8182 9.8182 9.8182 5.4142 0 9.8182-4.4073 9.8182-9.8182 0-5.411-4.404-9.8182-9.8182-9.8182Z"
			fillRule="evenodd"
		/>
	</svg>
);
