import { FC } from 'react';

import { IconProps } from './types';

export const DocumentsIcon: FC<IconProps> = ({ size, height = 24, width = 24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={size ?? width}
		height={size ?? height}
	>
		<path
			fill="#06519E"
			d="M19.64 19.5H8.86c-.7095 0-1.4333-.3142-1.9877-.8558C6.3179 18.0917 6 17.3875 6 16.6833V2.8167c0-.7042.3179-1.4084.8723-1.9609C7.4267.3142 8.1516 0 8.86 0h7.59c.2926 0 .572.1192.7777.3142l4.95 4.875c.2068.2058.3223.4875.3223.7691v10.725c0 .7042-.3179 1.4084-.8723 1.9609-.5544.5416-1.2782.8558-1.9877.8558Zm.86-11.9643v9.3c0 .0997-.0698.2768-.2258.4318-.1571.166-.3284.2325-.4287.2325H9.1545c-.1003 0-.2727-.0664-.4287-.2325-.156-.155-.2258-.3321-.2258-.4318V2.6643c0-.0997.0698-.2768.2258-.4318.156-.166.3284-.2325.4287-.2325h5.891v4.4286c0 .6089.4876 1.1071 1.0909 1.1071H20.5ZM19.5 5.5 17 3v2.5h2.5Z"
			fillRule="evenodd"
		/>
		<path
			fill="#06519E"
			d="M15.4136 24H4.7227c-.7036 0-1.4214-.3164-1.9712-.8618-.5499-.5564-.8651-1.2764-.8651-1.9746V7.2c0-.6.4887-1.091 1.0909-1.091S4.0682 6.6 4.0682 7.2v13.9636c0 .0982.0698.2728.2258.4255.156.1636.3284.229.4287.229h10.691c.6032 0 1.0908.491 1.0908 1.091S16.017 24 15.4136 24Z"
			fillRule="evenodd"
		/>
	</svg>
);
