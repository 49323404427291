import type { GetProps } from 'antd';

import Icon from '@ant-design/icons';

type CustomIconComponentProps = GetProps<typeof Icon>;

const IconSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16">
		<path
			fill="currentColor"
			transform="translate(0.00219727 0)"
			d="M7.9955459 15.999817L7.9999094 15.999817C12.409314 15.999817 15.997637 12.413676 15.999818 8.0042725L15.999818 7.3351889C15.999818 6.9337392 15.674731 6.6079245 15.272553 6.6079245C14.870377 6.6079245 14.545289 6.9337392 14.545289 7.3351889L14.545289 8.0035448C14.543835 11.611504 11.607141 14.545288 7.9999094 14.545288L7.996273 14.545288C4.3868599 14.543107 1.4530753 11.605685 1.4545299 7.9962721C1.4559844 4.3883133 4.3919506 1.4545288 7.9999094 1.4545288L8.0035458 1.4545288C8.9257174 1.4552561 9.8217068 1.6457994 10.663879 2.0210679C11.032602 2.1832478 11.460961 2.0188861 11.623868 1.6523447C11.787502 1.2858034 11.622414 0.85599023 11.255873 0.69235575C10.226066 0.23345187 9.1315327 0.00072726444 8.0042734 0L7.9999094 0C3.5912328 0 0.002182788 3.5861409 0 7.9955449C-0.0021807984 12.407131 3.5846872 15.997636 7.9955459 15.999817Z"
		/>
		<path
			fill="currentColor"
			transform="translate(5.09287 1.45835)"
			d="M2.3950636 8.521903C2.5310619 8.6586285 2.7165143 8.7349911 2.9092395 8.7349911C3.1026919 8.7349911 3.2874169 8.6579008 3.4234154 8.5211754L10.696059 1.2412585C10.98042 0.95689815 10.98042 0.49726704 10.696059 0.21290666C10.411699 -0.070726462 9.9520683 -0.071453728 9.6677074 0.21363392L2.9092395 6.9786477L1.2416222 5.3117576C0.9572618 5.0273972 0.49763069 5.0273972 0.21327029 5.3117576C-0.071090095 5.596118 -0.071090095 6.0557489 0.21327029 6.3401093L2.3950636 8.521903Z"
		/>
	</svg>
);

export const IconApply = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={IconSvg} {...props} />
);
