import { NextRouter } from 'next/router';

import { REDIRECT_TO_QUERY } from '@/constants/authorization';
import { AvoidRedirectList } from '@/constants/common';
import { CompanyType } from '@/types/common-entities';
import { Supplier } from '@/types/supplier';
import { User } from '@/types/user';

import { LkInfo } from './MobileMenu.types';

export const getLkInfo = (router: NextRouter, user?: User, userCompany?: Supplier): LkInfo => {
	if (user)
		return {
			link: user.company_type === CompanyType.Warehouse ? '/storage-account' : '/personal-account',
			mainText: 'Личный кабинет',
			extraText: userCompany?.title_short ?? userCompany?.title_short_en,
		};

	let link = '/login';

	if (!AvoidRedirectList.some((path) => router.pathname.includes(path))) {
		link = `/login?${REDIRECT_TO_QUERY}=${router.asPath}`;
	} else if (router.query[REDIRECT_TO_QUERY]) {
		link = `/login?${REDIRECT_TO_QUERY}=${router.query[REDIRECT_TO_QUERY]}`;
	}

	return {
		mainText: 'Авторизуйтесь',
		extraText: 'для покупки и продажи рыбопродукции',
		link,
	};
};
