import { FC } from 'react';

import { IconProps } from './types';

export const CardIcon: FC<IconProps> = ({ size, height = 18, width = 24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 18"
		width={size ?? width}
		height={size ?? height}
	>
		<path
			fill="#06519E"
			d="M3.2727 17.4545h17.4546c1.8043 0 3.2727-1.4683 3.2727-3.2727V3.2728C24 1.4683 22.5316 0 20.7273 0H3.2727C1.4684 0 0 1.4684 0 3.2727v10.9091c0 1.8044 1.4684 3.2727 3.2727 3.2727Zm-1.0909-12V3.2727c0-.601.491-1.0909 1.091-1.0909h17.4545c.6022 0 1.0909.4898 1.0909 1.091v2.1817H2.1818Zm0 2.1819h19.6364v6.5454c0 .6025-.4884 1.091-1.091 1.091H3.2728c-.6 0-1.0909-.4888-1.0909-1.091V7.6364Z"
			fillRule="evenodd"
		/>
	</svg>
);
