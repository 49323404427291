export const DownloadIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
		<path
			fill="#06519E"
			transform="translate(1 2)"
			d="M18.994715 15.24C18.671715 15.24 18.354715 15.09 18.161716 14.8C17.854715 14.34 17.978716 13.72 18.437716 13.41C19.412716 12.76 19.994715 11.67 19.994715 10.5C19.993715 8.5699997 18.423716 7 16.494715 7L14.703715 7C14.261716 7 13.871716 6.71 13.745715 6.29C13.467715 5.3600001 12.962715 4.5 12.283715 3.8099999C11.162716 2.6600001 9.6627159 2.02 8.0607157 2C6.4487157 2.01 4.9447155 2.5899999 3.7997155 3.71C1.4347155 6.0300002 1.3937155 9.8299999 3.7087154 12.2C4.0947156 12.59 4.0877156 13.23 3.6927154 13.61C3.2987156 14 2.6647155 13.99 2.2787156 13.6C-0.80628449 10.45 -0.75228447 5.3699999 2.4007156 2.28C3.9277155 0.79000002 5.9697156 0.0099999998 8.0817156 0C10.218716 0.029999999 12.217715 0.88 13.712715 2.4100001C14.440716 3.1500001 15.019715 4.04 15.410715 5L16.493715 5C19.525715 5 21.993715 7.4699998 21.994715 10.5C21.994715 12.34 21.080715 14.05 19.549715 15.07C19.378716 15.19 19.184715 15.24 18.994715 15.24Z"
			fillRule="evenodd"
		/>
		<path
			fill="#06519E"
			transform="translate(6.99347 11)"
			d="M4.0002499 7.5900002L1.70725 5.29C1.31625 4.9000001 0.68425 4.9000001 0.29324999 5.29C-0.097750001 5.6799998 -0.097750001 6.3200002 0.29324999 6.71L4.2772498 10.69C4.4472499 10.87 4.68325 10.98 4.94625 11C4.9642501 11 4.9822502 11 5.0002499 11C5.0102501 11 5.0212498 11 5.03125 11C5.3032498 10.99 5.5482502 10.87 5.7232499 10.69L9.7072496 6.71C10.09825 6.3200002 10.09825 5.6799998 9.7072496 5.29C9.3162498 4.9000001 8.6842499 4.9000001 8.2932501 5.29L6.0002499 7.5900002L6.0002499 1C6.0002499 0.44999999 5.5522499 0 5.0002499 0C4.4482498 0 4.0002499 0.44999999 4.0002499 1L4.0002499 7.5900002Z"
			fillRule="evenodd"
		/>
	</svg>
);
